.banner-wrapper {
    height: 300px;
    width: 100vw;
    background-color: #ebebeb;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #393839;
    border-bottom: 1px solid #393839;

    .banner-top {
        font-size: 4em;
        padding: 20px;
        text-align: center;
        font-family: 'Black Ops One', cursive;
    }

    .banner-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 90vw;
        height: 150px;
        grid-gap: 20px;
        text-align: center;

        .col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #393839;
            border-bottom: 3px solid transparent;
            border-radius: 10px;
            transition: 0.5s ease-in-out;

            &:hover {
                border-bottom: 3px solid #393839;
            }

            .logo-wrapper {
                font-size: 4em;
                margin-bottom: 20px;
            }

            .step {
                font-size: 2em;
            }
        }
    }
}

@media screen and (max-width: 875px) {
    .banner-wrapper {
        height: 350px;
    }
}
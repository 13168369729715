.footer-container {
    height: 100px;
    width: 100vw;
    background-color: #393839;
    color: white;
    -webkit-box-shadow: 0px -5px 8px 1px rgba(0,0,0,0.8); 
    box-shadow: 0px -5px 8px 1px rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .three-col {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 90vw;
        font-size: 1.5em;
        font-weight: 900;

        .logo-wrapper {
            img {
                height: 80px;
                width: 80px;
            }
        }
    }
}

@media screen and (max-width: 875px) {
    .footer-container {
        .three-col {
            font-size: 1em;

            .logo-wrapper {
                img {
                    height: 70px;
                    height: 70px;
                    margin-left: 10px;
                }
            }
        }
    }
}
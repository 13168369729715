.contact-container {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    .title {
        width: 90vw;

        h1 {
            margin-bottom: 5px;
            font-size: 3.5em;
        }
    }

    .contact-grid {
        display: flex;
        width: 90vw;

        .left-column {
            flex: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .right-column {
            flex: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 50px;
            padding: 20px;
            background-color: #ebebeb;

            .contact-title {
                text-align: center;
                font-size: 2em;
                color: #4b4b4b;
                margin-bottom: 30px;
            }

            .info-wrapper {
                display: flex;
                align-items: center;
                padding: 10px;
                transition: all 0.5s ease-in-out;
                text-decoration: none;
                color: black;
                margin-left: 40px;

                &:hover {
                    color: #BD1E2C;
                }

                .info-logo {
                    font-size: 2em;
                    padding: 20px;
                }

                
                .info {
                    font-size: 1.6em;
                }
            }
        }
    }
}

@media screen and (max-width: 875px) {
    .contact-container {   
        .title {
            h1 {
                font-size: 3em;
            }
        }
    
        .contact-grid {
            display: grid;
            grid-template-columns: 1fr; 
            width: 110vw;   
        }
    }    
}
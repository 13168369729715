.about-container {
    margin-top: 60px;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        width: 90vw;

        h1 {
            margin-bottom: 5px;
            font-size: 3.5em;
        }
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 85vw;
        margin-top: 40px;
        margin-bottom: 40px;

        .about {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2em;
            padding: 30px;
        }

        .about-img {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 100%;
                width: 400px;
                border-radius: 6px;
            }
        }
    }

    .hours-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 40px;
        color: #393839;

        .hours-title {
            font-size: 4em;
            margin-bottom: 20px;
            font-family: 'Black Ops One', cursive;
        }

        .hours {
            font-size: 1.7em;
        }
    }
}

@media screen and (max-width: 875px) {
    .about-container {
        overflow-x: hidden;

        .title {
            h1 {
                font-size: 3em;
            }
        }

        .grid-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            width: 99vw;
            
        }
    }
}
html {
  font-size: 62.5%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-family: 'montserrat';
  box-sizing: border-box;
}

body {
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.NavbarItems {
  background-color: #393839;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4em;
  -webkit-box-shadow: 0px 5px 8px 1px black;
  box-shadow: 0px 5px 8px 1px black;
}

.NavbarItems .navbar-logo {
  justify-self: start;
}

.NavbarItems .navbar-logo img {
  height: 90px;
  width: 100px;
}

.NavbarItems .menu-icon {
  display: none;
}

.NavbarItems .menu-icon .responsive-icon {
  color: white;
}

.NavbarItems .nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.NavbarItems .nav-menu .nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.NavbarItems .nav-menu .nav-links:hover {
  background-color: gray;
  color: red;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 875px) {
  .NavbarItems {
    position: relative;
  }
  .NavbarItems .navbar-logo {
    position: absolute;
    top: 5px;
    left: 20px;
    transform: translate(30%, 0%);
  }
  .NavbarItems .menu-icon {
    display: block;
    position: absolute;
    top: 8px;
    right: 40px;
    transform: translate(-100%, 50%);
    font-size: 1.4em;
    cursor: pointer;
  }
  .NavbarItems .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(65vh - 100px);
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .NavbarItems .active {
    background: #616061;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .NavbarItems .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100vw;
    display: table;
  }
  .NavbarItems .nav-links:hover {
    background-color: gray;
    border-radius: 0;
  }
}

.footer-container {
  height: 100px;
  width: 100vw;
  background-color: #393839;
  color: white;
  -webkit-box-shadow: 0px -5px 8px 1px rgba(0, 0, 0, 0.8);
  box-shadow: 0px -5px 8px 1px rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-container .three-col {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90vw;
  font-size: 1.5em;
  font-weight: 900;
}

.footer-container .three-col .logo-wrapper img {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 875px) {
  .footer-container .three-col {
    font-size: 1em;
  }
  .footer-container .three-col .logo-wrapper img {
    height: 70px;
    height: 70px;
    margin-left: 10px;
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

form h1 {
  font-size: 3em;
  margin-top: 120px;
  margin-bottom: 40px;
  text-align: center;
  color: #8d8e8f;
}

form input {
  color: black;
  padding: 5px 0px;
  width: 70%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #393839;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.6em;
  margin-bottom: 25px;
}

form input:focus {
  outline: none;
  border-bottom: 1px solid #BD1E2C;
}

form textarea {
  color: black;
  padding: 5px 0px;
  width: 70%;
  height: 100px;
  border: 1px solid #393839;
  background-color: transparent;
  transition: 0.5s all ease-in-out;
  font-size: 1.6em;
  margin-bottom: 25px;
}

form textarea:focus {
  outline: none;
  border: 1px solid #BD1E2C;
}

form .btn {
  cursor: pointer;
  height: 42px;
  width: 114px;
  font-size: 1.6em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #393839;
  color: white;
}

form .btn:active, form .btn:focus {
  outline: none;
}

form .btn:hover {
  border: 1px solid #393839;
  background-color: transparent;
  color: #BD1E2C;
}

@media screen and (max-width: 875px) {
  form {
    width: 90vw;
  }
}

.home-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

.home-container .background-img {
  height: calc(100vh - 200px);
  width: 100vw;
  background-image: url(../components/images/usa.jpg);
  filter: blur(5px);
  -webkit-filter: blur(5px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-container .home-text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 40%;
  padding: 20px;
}

.home-container .home-text-box .title {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 2.6em;
  text-transform: uppercase;
}

.home-container .home-text-box .subtitle {
  margin-top: 10px;
  color: white;
  text-align: center;
  font-size: 2.3em;
}

.home-container .home-text-box .btn {
  margin-top: 25px;
  height: 45px;
  width: 180px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  font-size: 1.5em;
  font-weight: bold;
  border: 1px solid transparent;
}

.home-container .home-text-box .btn:hover {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 875px) {
  .home-container .home-text-box {
    width: 70%;
    height: 50%;
  }
  .home-container .home-text-box .title {
    font-size: 2em;
  }
  .home-container .home-text-box .subtitle {
    margin-top: 10px;
    font-size: 1.8em;
  }
}

.about-container {
  margin-top: 60px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-container .title {
  width: 90vw;
}

.about-container .title h1 {
  margin-bottom: 5px;
  font-size: 3.5em;
}

.about-container .grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 85vw;
  margin-top: 40px;
  margin-bottom: 40px;
}

.about-container .grid-wrapper .about {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  padding: 30px;
}

.about-container .grid-wrapper .about-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container .grid-wrapper .about-img img {
  height: 100%;
  width: 400px;
  border-radius: 6px;
}

.about-container .hours-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 40px;
  color: #393839;
}

.about-container .hours-wrapper .hours-title {
  font-size: 4em;
  margin-bottom: 20px;
  font-family: 'Black Ops One', cursive;
}

.about-container .hours-wrapper .hours {
  font-size: 1.7em;
}

@media screen and (max-width: 875px) {
  .about-container {
    overflow-x: hidden;
  }
  .about-container .title h1 {
    font-size: 3em;
  }
  .about-container .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 99vw;
  }
}

.banner-wrapper {
  height: 300px;
  width: 100vw;
  background-color: #ebebeb;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #393839;
  border-bottom: 1px solid #393839;
}

.banner-wrapper .banner-top {
  font-size: 4em;
  padding: 20px;
  text-align: center;
  font-family: 'Black Ops One', cursive;
}

.banner-wrapper .banner-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90vw;
  height: 150px;
  grid-gap: 20px;
  text-align: center;
}

.banner-wrapper .banner-grid .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #393839;
  border-bottom: 3px solid transparent;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.banner-wrapper .banner-grid .col:hover {
  border-bottom: 3px solid #393839;
}

.banner-wrapper .banner-grid .col .logo-wrapper {
  font-size: 4em;
  margin-bottom: 20px;
}

.banner-wrapper .banner-grid .col .step {
  font-size: 2em;
}

@media screen and (max-width: 875px) {
  .banner-wrapper {
    height: 350px;
  }
}

.services-container {
  margin-top: 60px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.services-container .title {
  width: 90vw;
}

.services-container .title h1 {
  margin-bottom: 5px;
  font-size: 3.5em;
}

.service-lists {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  margin-top: 40px;
  margin-bottom: 40px;
}

.service-wrapper .service-title {
  font-size: 3.2em;
  font-family: 'Black Ops One', cursive;
  text-align: center;
}

.service-wrapper .service-subtitle {
  font-size: 2em;
  text-align: center;
}

.service-wrapper .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-wrapper .image-wrapper img {
  height: 300px;
  width: 100%;
  border-radius: 6px;
  margin: 22px;
}

.service-wrapper .price-wrapper {
  display: flex;
  justify-content: space-between;
  width: 43%;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.service-wrapper .price-wrapper .timeframe {
  width: 198px;
}

.service-wrapper .price-wrapper .price {
  font-weight: 900;
}

.service-wrapper .service-list {
  font-size: 1.7em;
  width: 43%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 42px;
}

.service-wrapper .service-list li {
  margin-bottom: 5px;
}

.pcs {
  background-color: #ebebeb;
  padding: 40px;
}

.pcs .pcs-img {
  width: 400px;
  height: 100%;
}

@media screen and (max-width: 875px) {
  .services-container .title h1 {
    font-size: 3em;
  }
  .service-wrapper {
    width: 90vw;
  }
  .service-wrapper .price-wrapper {
    width: 90%;
  }
  .service-wrapper .service-list {
    width: 75%;
  }
}

.contact-container {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.contact-container .title {
  width: 90vw;
}

.contact-container .title h1 {
  margin-bottom: 5px;
  font-size: 3.5em;
}

.contact-container .contact-grid {
  display: flex;
  width: 90vw;
}

.contact-container .contact-grid .left-column {
  flex: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container .contact-grid .right-column {
  flex: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #ebebeb;
}

.contact-container .contact-grid .right-column .contact-title {
  text-align: center;
  font-size: 2em;
  color: #4b4b4b;
  margin-bottom: 30px;
}

.contact-container .contact-grid .right-column .info-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  color: black;
  margin-left: 40px;
}

.contact-container .contact-grid .right-column .info-wrapper:hover {
  color: #BD1E2C;
}

.contact-container .contact-grid .right-column .info-wrapper .info-logo {
  font-size: 2em;
  padding: 20px;
}

.contact-container .contact-grid .right-column .info-wrapper .info {
  font-size: 1.6em;
}

@media screen and (max-width: 875px) {
  .contact-container .title h1 {
    font-size: 3em;
  }
  .contact-container .contact-grid {
    display: grid;
    grid-template-columns: 1fr;
    width: 110vw;
  }
}

.success-email {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.success-email div {
  font-size: 2em;
}

.success-email .btn {
  cursor: pointer;
  height: 42px;
  width: 114px;
  font-size: 1.6em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #393839;
  color: white;
}

.success-email .btn:active, .success-email .btn:focus {
  outline: none;
}

.success-email .btn:hover {
  border: 1px solid #393839;
  background-color: transparent;
  color: #BD1E2C;
}

.error-email {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: red;
  height: 100%;
  width: 100%;
  text-align: center;
}

.error-email .btn {
  cursor: pointer;
  height: 42px;
  width: 114px;
  font-size: 1.6em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: #393839;
  color: white;
}

.error-email .btn:active, .error-email .btn:focus {
  outline: none;
}

.error-email .btn:hover {
  border: 1px solid #393839;
  background-color: transparent;
  color: #BD1E2C;
}

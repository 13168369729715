form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;

    h1 {
        font-size: 3em;
        margin-top: 120px;
        margin-bottom: 40px;
        text-align: center;
        color: #8d8e8f;
    }

    input {
        color: black;
        padding: 5px 0px;
        width: 70%;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 1px solid #393839;
        background-color: transparent;
        transition: 0.5s all ease-in-out;
        font-size: 1.6em;
        margin-bottom: 25px;
    
        &:focus {
            outline: none;
            border-bottom: 1px solid #BD1E2C;
        }
    }

    textarea {
        color: black;
        padding: 5px 0px;
        width: 70%;
        height: 100px;
        border: 1px solid #393839;
        background-color: transparent;
        transition: 0.5s all ease-in-out;
        font-size: 1.6em;
        margin-bottom: 25px;
    
        &:focus {
            outline: none;
            border: 1px solid #BD1E2C;
        }
    }

    .btn {
        cursor: pointer;
        height: 42px;
        width: 114px;
        font-size: 1.6em;
        font-weight: 500;
        border: 1px solid transparent;
        transition: all 0.5s ease-in-out;
        background-color: #393839;
        color: white;
    
        &:active,
        &:focus {
            outline: none;
        }
    
        &:hover {
            border: 1px solid #393839;
            background-color: transparent;
            color: #BD1E2C;
        }
    }
}

@media screen and (max-width: 875px) {
    form {
        width: 90vw;
    }
}
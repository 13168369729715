html {
    font-size: 62.5%;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    font-family: 'montserrat';
    box-sizing: border-box;
}

body {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}
.home-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: hidden;

    .background-img {
        height: calc(100vh - 200px);
        width: 100vw;
        background-image: url(../components/images/usa.jpg);
        filter: blur(5px);
        -webkit-filter: blur(5px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .home-text-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0, 0.4);
        border: 3px solid #f1f1f1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 40%;
        padding: 20px;

        .title {
            color: white;
            font-weight: bold;
            text-align: center;
            font-size: 2.6em;
            text-transform: uppercase;
        }

        .subtitle {
            margin-top: 10px;
            color: white;
            text-align: center;
            font-size: 2.3em;
        }

        .btn {
            margin-top: 25px;
            height: 45px;
            width: 180px;
            border-radius: 5px;
            transition: 0.3s ease-in-out;
            font-size: 1.5em;
            font-weight: bold;
            border: 1px solid transparent;

            &:hover {
                background-color: transparent;
                border: 1px solid white;
                color: white;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 875px) {
    .home-container {    
        .home-text-box {
            width: 70%;
            height: 50%;
    
            .title {
                font-size: 2em;
            }
    
            .subtitle {
                margin-top: 10px;
                font-size: 1.8em;
            }
        }
    }    
}
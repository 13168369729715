.services-container {
    margin-top: 60px;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    .title {
        width: 90vw;

        h1 {
            margin-bottom: 5px;
            font-size: 3.5em;
        }
    }
}

.service-lists {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    margin-top: 40px;
    margin-bottom: 40px;

    .service-title {
        font-size: 3.2em;
        font-family: 'Black Ops One', cursive;
        text-align: center;
    }

    .service-subtitle {
        font-size: 2em;
        text-align: center;
    }

    .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 300px;
            width: 100%;
            border-radius: 6px;
            margin: 22px;
        }
    }

    .price-wrapper {
        display: flex; 
        justify-content: space-between;
        width: 43%;
        font-size: 2.5em;
        margin-bottom: 30px;

        .timeframe {
            width: 198px;
        }

        .price {
            font-weight: 900;
        }
    }

    .service-list {
        font-size: 1.7em;
        width: 43%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 42px;

        li {
            margin-bottom: 5px;
        }
    }
}

.pcs {
    background-color: #ebebeb;
    padding: 40px;

    .pcs-img {
        width: 400px;
        height: 100%;
    }
}

@media screen and (max-width: 875px) {
    .services-container {
        .title {
            h1 {
                font-size: 3em;
            }
        }
    }
    
    .service-wrapper {
        width: 90vw;
    
        .price-wrapper {
            width: 90%;
        }
    
        .service-list {
            width: 75%;
        }
    }
}